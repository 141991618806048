.navbar{
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 30px;
    background-color: rgba(255,255,255,0.25);
    backdrop-filter: blur(4px);
    --webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255,255,255,1);
    position: fixed;
    z-index: 2;

    @media screen and (max-width: 768px) {
        background-color: white;
        backdrop-filter: none;
        border: 1px solid rgba(255,255,255,0.18);
        box-shadow: 0px 0.5px 3px 0;
    }

    @media screen and (max-width: 430px) {
        padding: 5px 30px;
    }

}

.navbar__logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img{
        width: 90px;
        height: auto;
    }

    @media screen and (max-width: 768px) {
        flex: 1;
        justify-content: center;
    }

    @media screen and (max-width: 430px) {
        img{
            width: 50px;
        }
    }
}

.navbar__links{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;

    li{
        margin: 0 20px;
        cursor: pointer;
        flex-direction: column;

        div{
            width: 5px;
            height: 5px;
            background-color: transparent;
            border-radius: 50%;
            margin-bottom: 5px;
            transition: all 0.3s ease-in-out;

        }

        a{
            color: var(--gray-color);
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;

            transition: all 0.3s ease-in-out;

            &:hover{
                color: var(--secondary-color);
            }
        }

        &:hover{
            div{
                background-color: var(--secondary-color);
            }
        }
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
}