.app__social{
    position: fixed;
    bottom: 5px;
    left: 10px;

    width: 100px;
    height: 160px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 100;
    
    svg{
        width: 50px;
        height: 50px;
        color: var(--secondary-color);
        background-color: white;
        cursor: pointer;

        transition: all 0.5s ease-in-out;

        &:hover{
            color: var(--gray-color);
        }

        @media screen and (max-width: 768px) {
            width: 40px;
            height: 40px;
        }

        @media screen and (max-width: 430px) {
            width: 30px;
            height: 30px;
        }
    }

    @media screen and (max-width: 768px) {
        bottom: 100px;
        left: 10px;
        width: 60px;
        height: 100px;
        gap: 10px;
    }

    @media screen and (max-width: 430px) {
        bottom: 80px;
        left: 10px;
        width: 40px;
        height: 100px;
        gap: 10px;
    }

}