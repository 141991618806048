.app__footer{
    flex: 1;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      
    }
    @media screen and (max-width:430px) {
        padding: 50px 0;
    }
}

.app__footer-form{
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;

    div{
        width: 100%;
        margin: 0.75rem 0;
        border-radius: 10px;
        cursor: pointer;
        background-color: var(--primary-color);

        transition:  all 0.3s ease-in-out;

        input, textarea{
            width: 100%;
            padding: 0.95rem;
            border: none;
            border-radius: 7px;
            background-color: var(--primary-color);

            font-family: var(--font-base);
            color: var(--secondary-color);
            outline: none;
        }

        textarea{
            height: 170px;
        }

        &:hover{
            box-shadow: 0 0 25px var(--primary-color);
        }

        .error-input{
            // margin: 0!important;
            border: 1.5px solid red;
          }

        @media screen and (max-width: 768px) {
      
        }
        @media screen and (max-width:430px) {
            margin: 0.3rem 0;

            input, textarea{
                padding: 0.7rem;
                border-radius: 5px
            }
    
            textarea{
                height: 120px;
            }
        }
    }

    button{
        padding: 0.5rem 2rem;
        border-radius: 10px;
        color: lightgray;
        font-weight: 500;
        outline: none;
        // margin: 2rem 0 0 0;
        font-family: var(--font-base);
        transition: all 0.3s ease-in-out;

        
        @media screen and (max-width:430px) {
            margin: 0.5rem 0 0 0;
        }
    }

    .enabled-button{
        border: 2px solid var(--secondary-color);
        background-color: var(--white-color);
        color: var(--secondary-color);
        cursor: pointer;
        font-weight: 600;

        &:hover{
            box-shadow: 1px 1px 5px 0;
            border: 1px solid var(--secondary-color);
            font-weight: 700;
        }
    }
    
    @media screen and (max-width:430px) {
        width: 85%;
    }

    .app__footer_button-message{
        position: relative;
        background-color: transparent;
        flex-direction: column;
        width: fit-content;
    }
    .app__result-message{
        position: absolute;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 50px;
        width: max-content;

        span{
            font-weight: 600;
            font-style: italic;

            @media screen and (max-width: 430px) {
                font-size: small;
           }
        }

        .success-message{
            color: var(--secondary-color);
        }
        .failure-message{
            color: var(--bs-danger);
        }
    }
}