.navbar-mobile{
    width: 100%;

    display: none;
    justify-content: space-between;
    align-items: center;

    padding: 10px 30px;
    background-color: #fff;
    border: 1px solid rgba(255,255,255,0.18);
    box-shadow: 0px -0.5px 3px 0;
    position: fixed;
    bottom: 0;
    z-index: 2;

    @media screen and (max-width: 768px) {
        display: flex;
        padding: 5px 5px 5px 5px;
    }
}

.navbar-mobile__links{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    list-style: none;

    li{
        margin: 0 20px;
        cursor: pointer;
        flex-direction: column;

        div{

            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-bottom: 5px;
            transition: all 0.3s ease-in-out;


            svg{
                width: 20px;
                height: 20px;
                color: var(--gray-color);
                transition: all 0.3s ease-in-out;
            }

        }

        a{
            color: var(--gray-color);
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;

            transition: all 0.3s ease-in-out;

            &:hover{
                color: var(--secondary-color);
            }
        }

        &:hover{
            div{
                background-color: var(--secondary-color);

                svg{
                    color: white;
                }
            }

            a{
                color: var(--secondary-color);
            }
        }

        @media screen and (max-width: 430px) {
            margin: 0 10px;

            div{
                width: 25px;
                height: 25px;
                margin-bottom: 5px;

                svg{
                    width: 15px;
                    height: 15px;

                }

            }

            a{
                font-size: 10px;
            }
        }
    }
}