.app{
    background-color: var(--primary-color);
    font-family: var(--font-base);
    overflow: hidden;

  @media screen and (max-width: 768px) {
      margin-bottom: 100px;
  }
  @media screen and (max-width:430px) {
    margin-bottom: 90px;

  }
}

.app__white-bg{
    background-color: var(--white-color);
}

.app__primary-bg{
    background-color: var(--primary-color);
}

.app__container{
    width: 100vw;
    min-height: 100vh;

    display: flex;
    flex-direction: row;

    // padding: 10px 40px;

    @media screen and (max-width: 430px) {
      min-height: auto;
    }
}

.app__flex{
    display: flex;
    justify-content: center;
    align-items: center;

}

.app__wrapper{
    flex: 1;
    width: 100%;
    height: inherit;
    flex-direction: column;
    // padding: 4rem 2rem;
    position: relative;

    @media screen and (max-width: 430px) {
      height: 100%;
    }

    .copyright {
      position: absolute;
      bottom: 20px;
      right: 30px;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      @media screen and (max-width: 768px) {
        bottom: 20px;
        right: 30px;
      }
      @media screen and (max-width:430px) {
        bottom: 10px;
        right: 10px;
      }

      p {
        text-transform: uppercase;
        color: var(--gray-color);
        font-style: italic;

        @media screen and (max-width: 768px) {
          font-size: small;
        }

        @media screen and (max-width: 430px) {
          font-size: xx-small;
        }
      }
    }
}



.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;

  span {
    color: var(--secondary-color);
  }

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 430px) {
    font-size: 1.25rem;
  }
}

.p-text {
  font-size: 16px;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }

  @media screen and (max-width: 430px) {
    font-size: 12px;
  }
}

.bold-text {
  font-size: 24px;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 430px) {
    font-size: 15px;
  }
}