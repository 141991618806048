#home{
    position: relative;
    background: url('../../assets/bgIMG.png');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper{
        padding: 0;

        .copyright{
            display: none;
        }
    }
}

.app__header{
    flex: 1;
    width: 100vw;
    height: 100vh;
    flex-direction: row;

    padding: 6rem 2rem 0;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    @media screen and (max-width: 430px) {
        display: grid;
        grid-template-rows: 100px 300px 100px;
    }
}

.app__header-info{
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    margin: 0px 2rem;

    position: relative;

    @media screen and (max-width: 768px) {
        transform: scale(1.1)!important;
        width: 70vh;
    }

    @media screen and (max-width: 430px) {
        width: 70vh;
        transform: scale(0.95) !important;
        top: -60px;
        left: -30px;
    }
}

.app__header-badge{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    position: absolute;
    top: 40px;
    left: 150px;

    .badge-cmp{
        gap: 20px;
    }

    .badge-cmp, .tag-cmp{
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }

    .tag-cmp{
        flex-direction: column;
        margin-top: 3rem;

        p{
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }
    span{
        font-size: 2.5rem;
    }

    @media screen and (max-width: 768px) {
        transform: scale(0.8);
        top: 0;
        left: 45px;
        align-items: flex-start;

        .tag-cmp{
            margin-top: 1rem;
        }
    }

    @media screen and (max-width: 430px) {
        transform: scale(0.6);
        top: -1px;
    }
}

.app__header-circles{
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div{
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0, 0, 0,0.1);

        img{
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1){
        width: 100px;
        height: 100px;
    }
    div:nth-child(2){
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }
    div:nth-child(3){
        width: 70px;
        height: 70px;
    }

    // @media screen and (max-width: 768px) {
    //     flex-direction: row;
    //     z-index: 1;
    //     width: 100%;

    //     div{
    //         transform: translateY(-65px) translateX(20px);
    //     }

    //     div:nth-child(3){
    //         width: 85px;
    //         height: 85px;
    //     }
    // }
    @media screen and (max-width: 768px) {
        flex-direction: row;
        z-index: 1;
        width: 100%;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        grid-template-rows: 33% 33% 33%;
        margin: 0;

        // div{
        //     transform: translateY(-65px) translateX(20px);
        // }

        div:nth-child(1){
            width: 120px;
            height: 120px;
            grid-column: 1;
            align-self: self-end;
            justify-self: self-end;
        }
        div:nth-child(2){
            width: 150px;
            height: 150px;
            grid-column: 3;
            grid-row: 2;
            align-self: self-end;
            justify-self: center;
            margin: 0;
        }
        div:nth-child(3){
            width: 120px;
            height: 120px;
            grid-column: 5;
            align-self: self-end;
            justify-self: self-start;
        }
    }

    @media screen and (max-width: 430px) {
        // display: grid;
        // grid-template-columns: 1fr 1fr 1fr;

        div:nth-child(1){
            transform: scale(0.6);
            grid-column: 2;
            grid-row: 2;
            align-self: end;
        }
        div:nth-child(2){
            transform: scale(0.55);
            align-self: center;
        }
        div:nth-child(3){
            transform: scale(0.6);
            grid-column: 4;
            grid-row: 2;
            align-self: end;
        }
    }
}

.app__header-img{
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    img{
        object-fit: contain;
        z-index: 1;
        width: 100%;

        @media screen and (max-width: 768px) {
            width: 65%;
        }

        @media screen and (max-width: 430px) {
            width: 50%;
        }
    }


    .overlay_circle{
        position: absolute;
        right: -15px;
        bottom: -55px;
        z-index: 0;
        width: 90%;

        @media screen and (max-width: 768px) {
            width: 65%;
            right: 18%;
            bottom: -20px;
        }

        @media screen and (max-width: 430px) {
            width: 50%;
            right: 25%;
       }
    }

    @media screen and (max-width: 768px) {
        height: 70%;
    }

    @media screen and (max-width: 430px) {
        height: 100%;
    }
}

