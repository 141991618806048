.app__about{
    flex: 1;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        padding: 70px 0;
    }
    @media screen and (max-width: 430px) {
        padding: 30px 0;
    }
}

.app__profiles{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;

    @media screen and (max-width: 430px) {
        margin: 1rem 0;
    }
}

.app__profile-item{
    width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;

    @media screen and (max-width: 768px) {
        margin: 1rem;
    }
    @media screen and (max-width: 430px) {
        margin: 0.5rem;
    }

    img{
        width: 100%;
        height: 215px;
        border-radius: 15px;
        object-fit: contain;

        @media screen and (max-width: 768px) {
            height: 190px;
        }
        @media screen and (max-width: 430px) {
            height: 150px;
        }
    }
}