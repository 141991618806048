.app__testimonials{
    flex: 1;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        padding: 70px 0;
    }
    
    @media screen and (max-width: 430px) {
        padding: 30px 0;
    }

}

.app__testimonial-container{
    max-width: 1200px;
    width: 100%;
}

// Carousel App Custom
.carousel-app-custom{
    ul li{
        cursor: grab;
    }
}


.app__testimonial-item{
    height: fit-content;
    justify-content: flex-start;
    flex-direction: column;
    margin: 2rem;
    padding: 1rem;
    border-radius: 2.5rem;
    background-color: #fff;
    color: #000;
    transition: all 0.3s ease;

    &:hover{
        box-shadow: 0 0 25px rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 768px) {
        margin: 1.5rem;
        height: 215px;
    }
    @media screen and (max-width:430px) {

    }
}


.app__testimonial-content{
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    svg{
        font-size: 42px;
        color: var(--gray-color);
    }

    .app__testimonial-feedback{
        width: inherit;
        font-style: italic;
        text-align: center;
        font-size: 16px;
        height: 100px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .app__testimonial-name{
        margin-top: 1rem;
        line-height: 1.5;
        text-align: center;
        color: var(--secondary-color);
    }

    .app__testimonial-company{
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        margin: 0 1.5rem;

        svg{
            font-size: 30px;
        }
    
        .app__testimonial-feedback{
            font-size: 13px;
            height: 70px;
        }
    
        .app__testimonial-name{
            font-size: 18px;
        }
    
        .app__testimonial-company{
            font-size: 13px;
        }
    }
    @media screen and (max-width:430px) {
        
    }

}

.app__testimonials-slide-buttons{
    gap: 50px;

    @media screen and (max-width: 768px) {
        gap: 40px;
    }
    @media screen and (max-width:430px) {
        gap: 40px;
    }
}
.app__testimonials-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 20px;
    color: var(--gray-color);
    border: 1px solid var(--gray-color);
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover{
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        color: #fff;
    }

    @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
    }
    @media screen and (max-width:430px) {
        width: 30px;
        height: 30px;
    }
}
