.app__works{
    flex: 1;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        padding: 70px 0;
    }
    
    @media screen and (max-width: 430px) {
        padding: 30px 0;
    }

}

.app__work-filter{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin: 4rem 0rem 2rem;

    @media screen and (max-width: 768px) {
        margin: 1rem 0rem 1rem;
    }
    @media screen and (max-width:430px) {
        margin: 0.5rem 0rem 0.5rem;
    }

    .app__work-filter-item{
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        margin: 0.5rem;
        border: 1px solid var(--gray-color);

        &:hover{
            background-color: var(--secondary-color);
            color: #fff;
            border-color: var(--secondary-color);
            box-shadow: 3px 3px 10px 0px gray;
        }

        @media screen and (max-width: 768px) {
            font-size: smaller;
        }
        @media screen and (max-width: 430px) {
            font-size: x-small;
        }
    }

    .item-active{
        background-color: var(--secondary-color);
        color: #fff;
        border-color: var(--secondary-color);
    }
}

.app__work-portfolio{
    max-width: 1200px;
    width: 100%;
}

// Carousel App Custom
.carousel-app-custom{
    ul li{
        cursor: grab;
    }
}


.app__work-item{
    height: fit-content;
    justify-content: flex-start;
    flex-direction: column;

    margin: 1rem;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;

    transition: all 0.3s ease;

    &:hover{
        box-shadow: 0 0 25px rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 768px) {
        height: 400px;
        margin: 1.5rem;
    }
    @media screen and (max-width:430px) {
        height: 370px;
        margin: 1rem 4rem;
    }
}

.app__work-img{
    width: 100%;
    height: 230px;
    cursor: pointer;

    position: relative;

    img{
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
        object-position: top;
    }

    @media screen and (max-width: 768px) {
        height: 180px;
    }
    @media screen and (max-width:430px) {
        height: 180px;
    }
}

.app__work-hover{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);

    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    div{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.5);
        color: #fff;

        margin: 1rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;

        svg{
            width: 50%;
            height: 50%;
            color: var(--white-color);
        }
    }
}

.app__work-content{
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    p{
        width: inherit;
    }

    h4{
        margin-top: 1rem;
        line-height: 1.5;
    }

    .app__work-tag{
        position: absolute;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: #fff;
        top: -25px;
    }
}

.app__work-slide-buttons{
    gap: 50px;

    @media screen and (max-width: 768px) {
        gap: 40px;
    }
    @media screen and (max-width:430px) {
        gap: 40px;
    }
}
.app__work-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 20px;
    color: var(--gray-color);
    border: 1px solid var(--gray-color);
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover{
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        color: #fff;
    }

    @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
    }
    @media screen and (max-width:430px) {
        width: 30px;
        height: 30px;
    }
}
