.app__skills{
    flex: 1;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width:430px) {
        padding: 30px 0;
    }
}

.app__skills-container{
    width: 80%;
    margin-top: 3rem;

    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px) {
      width: 90%;
    }
    @media screen and (max-width:430px) {
        flex-direction: column;
        margin: 1rem 0;
        gap: 2rem;
    }
}

.app__skills-list{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    margin-right: 5rem;

    @media screen and (max-width: 768px) {
        margin-right: 1rem;
    }
    @media screen and (max-width:430px) {
        margin: 0;
    }
}

.app__skills-item{
    flex-direction: column;
    text-align: center;
    margin: 1rem;
    transition:  all 0.3s ease-in-out;

    div{
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #fff;

        img{
            width: 50%;
            height: 50%;
        }

        &:hover{
            box-shadow: 0 0 25px #fef4f5;
        }
    }

    p{
        font-weight: 500;
        margin-top: 0.5rem;
    }

    @media screen and (max-width: 768px) {
        div{
            width: 56px;
            height: 56px;
        }
    }
    @media screen and (max-width:430px) {
        margin: 0.5rem;
    }
}

// Experience Section
.app__skills-exp{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

}

.app__skills-exp-item{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;

    @media screen and (max-width:430px) {
        margin: 0;
    }
}

.app__skills-exp-works{
    flex: 1;

    .app__skills-exp-work{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        cursor: pointer;

        h4{
            font-weight: 800;
        }
        
        p{
            font-weight: 400;
            color: var(--gray-color);
            margin-top: 5px;
        }

        @media screen and (max-width: 768px) {
            h4{
                font-size: 16px;
            }
            p{
                font-size: 12px;
            }
        }
    }
}

.app__skills-exp-year{
    margin-right: 3rem;

    p{
        font-weight: 800;
        color: var(--secondary-color);
        text-align: end;
    }

    @media screen and (max-width: 768px) {
      p{
        font-size: 16px;
      }
    }
}